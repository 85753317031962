/**
 * Created by jiachenpan on 16/11/18.
 */

export function isvalidUsername(str) {
  const valid_map = ['admin']
  return valid_map.indexOf(str.trim()) >= 0
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
// 输入框必填
export function required() {
  return { required: true, message: '必填项', trigger: 'blur' }
}
// 下拉必选
export function requiredC() {
  return { required: true, message: '必选项请选择', trigger: 'change' }
}
// 多选
export function requiredM() {
  return {
    required: true,
    validator: function(rule, value, callback) {
      if (value.length > 0) {
        callback()
      } else {
        callback(new Error('必选项请选择'))
      }
    },
    trigger: 'change'
  }
}
// 数字
export function isNum() {
  return { pattern: /^-?[0-9]+$/, message: '请填写数字', trigger: 'blur' }
}
// 校验整数，var 全局变量  /^\d+$/
export function isIntegerNum() {
  return { pattern: /^\d+$/, message: '请填写整数', trigger: 'blur' }
}

// 验证邮编，1-6位
export function isPostCode() {
  return { pattern: /^[0-9]{0,6}$/, message: '请输入长度小于6位的数字', trigger: 'blur' }
}

// 验证邮箱
export function validateEmail() {
  return {
    validator: function(rule, value, callback) {
      if (value) {
        var reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!reg.test(value)) {
          callback(new Error('请输入有效的邮箱,格式123456abc@163.com'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    trigger: 'change'
  }
}

// 手机号验证   /^1[345789]\d{9}$/
export function checkMobilePhone() {
  return { pattern: /^1[345789]\d{9}$/, message: '请输入13，17，18等开头11位手机号', trigger: 'blur' }
}

// 电话号验证   /^0\d{2,3}-?\d{7,8}$/ 改  /^\d+$|-/
export function checkPhone() {
  return { pattern: /^\d+$|-/, message: '请输入数字或带-的数字', trigger: 'blur' }
}
// 必须包含中文
export function hasZh() {
  return { pattern: /^(.*[\u4e00-\u9fa5]+.*)$/, message: '至少用含有一个中文', trigger: 'blur' }
}
// 请输入正确的邮箱
export function email() {
  return { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确的邮箱', trigger: 'blur' }
}
// 英文字母数字和点 ，校验tag
export function enPoint() {
  return { pattern: /^[a-zA-Z0-9\.]{0,30}$/, message: '只能包含大小写字母数字和英文点号，不超过30位', trigger: 'blur' }
}
