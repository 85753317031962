const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  userip: state => state.app.userip,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  isDDlogin: state => state.user.isDDlogin,
  actionUrl: state => state.user.actionUrl,
  permissions: state => state.user.permissions,
  permissionTag: state => state.user.permissionTag,
  tagsList: state => state.tags.tagsList,
  permission_routers: state => state.permission.routers,
  tagRouters: state => state.permission.tagRouters,
  addRouters: state => state.permission.addRouters,
  ddAllUser: state => state.userList.ddAllUser,
  allUserList: state => state.userList.allUserList
}

export default getters
