import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { getToken, getTokenPower } from '@/utils/auth' // 从cookie中读取token
import { getSystemToken } from '@/utils/systemid'
import { toLink } from '@/utils/index'
import { getLocalIp } from '@/api/login'

NProgress.configure({ showSpinner: false }) // 进度条设置

const internalPage = function(to, next) {
  if (to.path === '/transfer') {
    getLocalIp().then((res) => {
      console.log(res, 'ip')
      store.dispatch('setUserIp', res.data || '')
    }).catch(() => {
      store.dispatch('setUserIp', '')
    })
    const data = {
      token: to.query.token,
      expire: to.query.expire,
      userInfo: JSON.parse(to.query.userInfo)
    }
    console.log(data)
    store.dispatch('saveUserInfo', data).then(() => {
      toLink().then(path => {
        next(path)// 有配置面板跳新userCenterNew 无跳/
      }).catch(() => {
        next('/')
      })
      // next('/')
    }).catch(() => {
      next('/login')
    })
  } else {
    store.dispatch('GetUserInfo').then(res => { // 拉取用户信息
      store.dispatch('GenerateRoutes', {}).then(() => { // 根据roles权限生成可访问的路由表
        // 从登录（刷新页面）进来的时候通过判断cookie中的系统id来让用户进入哪个页面
        if (!getSystemToken()) {
          if ((store.getters.roles.userInfo.isFirstLogin || store.getters.roles.userInfo.expiredPassword) && !store.getters.isDDlogin) {
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            next({ path: '/resetPassword', replace: true })
          } else {
            if (to.path === '/userCenterNew') { // 放过
              next()
            } else {
              const usId = to.query.usId
              console.log(usId)
              usId ? next({ path: '/', replace: true, query: { usId: usId }}) : next({ path: '/', replace: true })
            }
          }
        } else {
          console.log(to)
          router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,replace为true的时候导航就不会留下记录了
        }
      }).catch((err) => {
        console.log(err)
        store.dispatch('LogOut').then(() => {
          Message.error(err || 'Verification failed, please login again 1')
          next({
            path: '/login' || '/'
          })
        })
      })
    }).catch((err) => {
      console.log(err)
      store.dispatch('LogOut').then(() => {
        Message.error(err || 'Verification failed, please login again 11')
        next({
          path: '/login' || '/'
        })
      })
    })
  }
}

const whiteList = ['/login', '/findPassword'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start()
  console.log(to)
  if (getToken()) {
    if (to.fullPath.indexOf('/login?redirect=') === 0) { // 有重定向路由的直接放过
      next()
    } else if (to.path === '/login' || to.path === '/findPassword') {
      next({ path: '/' })
      NProgress.done() // 如果当前页面是主页，则不会触发afterEach挂钩，因此手动处理它
    } else if (to.path === '/userCenterOld') {
      next()
    } else {
      if (store.getters.roles.length === 0) {
        // alert(to.path)
        // store.dispatch('setLanguage', store.getters.language)
        internalPage(to, next)
      } else {
        next()
      }
    }
  } else {
    // 通过链接打开权限系统
    if (window.location.href.indexOf('?code=') !== -1) {
      store.dispatch('LoginByUsername').then(res => {
        router.push({ path: '/homepage' })
      }).catch(err => {
        Message.error(err)
      })
    } else {
      if (to.path === '/transfer') { // 如果是从钉钉管理台来，存储code信息，并跳转到系统列表页
        const data = {
          token: to.query.token,
          expire: to.query.expire,
          userInfo: JSON.parse(to.query.userInfo)
        }
        console.log(data)
        store.dispatch('saveUserInfo', data).then(() => {
          toLink().then(path => {
            next(path)// 有配置面板跳新userCenterNew 无跳/
          }).catch(() => {
            next('/')
          })
          // next('/')
        }).catch(() => {
          next('/login')
        })
      }
      //  如果 cookie 中有当前地址参数进入内容页跳转
      if (getTokenPower()) {
        internalPage(to, next) //  内容页跳转
      } else {
        if (whiteList.indexOf(to.path) !== -1) {
          next()
        } else {
          next(`/login`) // 否则全部重定向到登录页
          NProgress.done()
        }
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
