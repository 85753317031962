import { constantRouterMap, routerMap } from '@/router'
import { bussinessTreeNew } from '@/api/menu-manage'
import { getSystemToken } from '@/utils/systemid'
import Layout from '@/views/layout/Layout'
import store from '@/store'

// 根据数据装载成路由格式
function formatRoutes(aMenu) {
  const tagRouter = []
  const aRouter = []
  let returnData = {}
  const language = store.getters.language
  for (const i in aMenu) {
    const childrenMenu = aMenu[i].childUserRight
    const routerItem = {
      path: `/${aMenu[i].umUrl.split('/')[1]}`,
      component: Layout,
      redirect: aMenu[i].umUrl,
      alwaysShow: true,
      meta: {
        title: language === 'zh' ? aMenu[i].umTitle : aMenu[i].umEnTitle,
        icon: 'system-info'
      }
    }
    const childRoute = []
    for (const j in childrenMenu) {
      // let components = componentsUrl(childrenMenu[j].urUrl);
      const route = {
        path: `${childrenMenu[j].urUrl}/index`,
        component: routerMap[childrenMenu[j].urUrl.split('/')[1]],
        name: childrenMenu[j].urEnName,
        meta: {
          title: language === 'zh' ? childrenMenu[j].urName : childrenMenu[j].urEnName,
          icon: 'system-info'
        }
      }
      const tagItem = {
        path: `${childrenMenu[j].urUrl}/index`,
        name: childrenMenu[j].urEnName,
        nameZh: childrenMenu[j].urName
      }
      tagRouter.push(tagItem)
      childRoute.push(route)
      routerItem.children = childRoute
    }
    aRouter.push(routerItem)
  }
  console.log('路由')
  console.log(aRouter)

  // 暂时获取路由的信息组合tag，后台返回路由后去掉
  constantRouterMap.forEach(item => {
    if (item.children) {
      item.children.forEach(item => {
        const tagPage = {
          path: `${item.path}`,
          name: item.name,
          nameZh: item.nameZh
        }
        tagRouter.push(tagPage)
      })
    }
  })
  returnData = {
    aRouter: aRouter,
    tagRouter: tagRouter
  }
  console.log(returnData)
  return returnData
}

const permission = {
  state: {
    routers: [],
    addRouters: [],
    tagRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      // 把写死的路由和动态的路由合并
      state.addRouters = routers.aRouter
      state.routers = constantRouterMap.concat(routers.aRouter)
      state.tagRouters = routers.tagRouter.concat([{ name: 'homepage', nameZh: '首页', path: '/homepage/index' }])
    }
  },
  actions: {
    // 调用后台接口，生成路由
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const usId = getSystemToken()
        if (usId) {
          bussinessTreeNew(usId).then(response => {
            const router = formatRoutes(response.data) // 写好页面后放开
            // const router = formatRoutes([])
            const accessedRouters = router
            commit('SET_ROUTERS', accessedRouters)
            resolve()
          }).catch(error => {
            console.log(error)
          })
          // 新菜单
          // bussinessTreeNew(usId).then(response => {
          //   console.log(response)
          // }).catch(err => {
          //   console.log(err)
          // })
        } else {
          resolve()
        }
      })
    }
  }
}

export default permission
