
const tags = {
  state: {
    tagsList: []
  },
  mutations: {
    UPDATE_TAGS: (state, list) => {
      state.tagsList = list
    }
  },
  actions: {
    SET_TAGS: ({ commit }, list) => {
      commit('UPDATE_TAGS', list)
    }
  }
}
export default tags
