import Cookies from 'js-cookie'

const TokenKey = 'system-Code-power'

export function getSystemToken() {
  return Cookies.get(TokenKey)
}

export function setSystemToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeSystemToken() {
  return Cookies.remove(TokenKey)
}
