// import Vue from 'vue'
import _ from 'lodash'
import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const { value } = binding
    const permissions = store.getters && store.getters.permissions
    if (value && permissions.length > 0) {
      const hasPermission = _.some(permissions, val => val.tag === value)
      // const hasPermission = _.some(permissions, { tag: value })
      // const hasPermission = permissions.filter(item => {
      //   return item.tag === value
      // }).length
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      // throw new Error(`permission! Like v-permission="system.view"`)
    }
  }
}
